<template>
  <div class="container">
    <Header></Header>
    <ChatArea></ChatArea>
    <InputArea></InputArea>
  </div>
</template>
<script>
import Header from "../components/Header.vue";
import ChatArea from "../components/ChatArea.vue";
import InputArea from "../components/InputArea.vue";

export default {
  components: { Header, ChatArea, InputArea },
  data() {
    return {
      sampleData: "",
    };
  },
  created() {},
  mounted() {},
  unmounted() {},
  methods: {},
};
</script>

<style scoped>
.container {
  /* width: 100vw;
  height: 100vh; */
  /* overflow-x: hidden;
  overflow-y: scroll; */
}
</style>

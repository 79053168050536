import axios from "axios";

export function getApiUrl() {
  const protocol = location.protocol;
  const hostName = location.host;
  let apiUrl = "http://localhost:8085/cc-bot";

  if(hostName !== "localhost:8080") {
    apiUrl = protocol + "//" + hostName + "/cc-bot";
  }
  console.log("##### API URL : " + apiUrl);

  return apiUrl;
}

export function greeting() {
  const url = getApiUrl() + "/api/message/block/greeting?Scenario-Code=default";
  return axios.get(url);
}

export function getAction(id, userCi) {
  let urlParams = new URL(location.href).searchParams;
  let legacyId = urlParams.get('legacyId');
  const url = getApiUrl() + `/api/message/action/${id}?timestamp=` + new Date().getTime();
  console.log("getAction URL: ", url);
  if(userCi === null || userCi === '' || typeof userCi === 'undefined') {
    userCi = sessionStorage.getItem("userCi");
  }
  console.log('USER CI : ' + userCi);
  console.log('LEGACY ID : ' + legacyId);
  return axios.get(url, {
    headers: {
      userCi: userCi,
      legacyId: legacyId,
      userName: sessionStorage.getItem("userName"),
      token: sessionStorage.getItem("token"),
      'Scenario-Code': sessionStorage.getItem('Scenario-Code')
    },
  });
}

export function authSuccess(ci, userName) {
  const url = getApiUrl() + `/api/message/block/498`;
  if(ci === null || ci === '' || typeof ci === 'undefined') {
    ci = sessionStorage.getItem("userCi");
  }
  if(userName === null || userName === '' || typeof userName === 'undefined') {
    userName = sessionStorage.getItem('userName');
  }
  return axios.get(url, {
    headers: { userCi: ci, userName: userName },
  });
}

export function scenarioGreeting(code, legacyId) {
  const url = getApiUrl() + `/api/message/block/greeting?Scenario-Code=${code}&legacyId=${legacyId}`;
  let userCi = sessionStorage.getItem("userCi");
  let userName = sessionStorage.getItem("userName");

  return axios.get(url, {
    headers: {
      userCi: userCi,
      userName: userName,
      legacyId: legacyId
    },
  });
}

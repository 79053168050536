<template>
  <div class="block">
    <div class="carousel-warpper scroll">
      <div class="item_container" v-for="(slide, idx) in this.msgs.balloons" :key="idx">
        <div class="items" v-for="(s, idx) in slide.sections" :key="idx">
          <TitleBox v-if="s.type === 'title'" :title="s"></TitleBox>
          <TextBox v-if="s.type === 'text'" :msg="s"></TextBox>
          <ImageBox v-if="s.type === 'file'" :file="s"></ImageBox>
          <ButtonType
            v-if="s.type === 'action'"
            :actions="s.actions"
            :btnStatus="btnStatus"></ButtonType>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TextBox from "@/components/balloons/textBox.vue";
import ImageBox from "@/components/balloons/ImageBox.vue";
import ButtonType from "@/components/balloons/ButtonType.vue";
import TitleBox from "@/components/balloons/TitleBox.vue";
// import { defineComponent } from "vue";
// import { Carousel, Navigation, Slide } from "vue3-carousel";
// import { carousel1, carousel2 } from "@/data/testData";

// import "vue3-carousel/dist/carousel.css";

export default {
  components: {
    // Carousel,
    // Slide,
    // Navigation,
    TextBox,
    ImageBox,
    ButtonType,
    TitleBox,
  },
  props: ["msgs", "btnStatus"],
  data() {
    return {};
  },
  mounted() {
    console.log("msgs: ", this.msgs);
    console.log("carousel: ");
    console.log("sections: ");
  },
  created() {
    this.$nextTick(() => {
      this.scroll();
    });
  },
  methods: {
    scroll() {
      const element = document.getElementById("chatBox");
      const height = document.getElementById("chatBox").scrollHeight;
      element.scrollTop = height;
    },
  },
};
</script>

<style scoped>
.carousel-warpper {
  display: flex;
  padding: 10px 0px;
  scroll-behavior: smooth;
  z-index: 1;
  /* width: 100%; */
  overflow-x: scroll !important;
  white-space: nowrap;
  margin-right: 20px;
}
.item_container {
  display: inline-block;
  margin: 0px 5px 10px 5px;
  background: white;
  padding: 10px;
  width: 240px;
  /* min-width: 272px;
  max-width: 272px; */
  border-radius: 10px;
}
.items {
  /* width: 100%; */
  /* float: left; */
}

.scroll::-webkit-scrollbar {
  display: none;
}

.scroll {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.block {
  width: 100%;
  overflow-x: hidden;
}
</style>

import { createStore } from "vuex";
// import { testData } from "../data/testData.js";
import { greeting, getAction, authSuccess, scenarioGreeting } from "@/api";

export default createStore({
  state: {
    msgBoxList: [],
    userCi: "",
    userName: "",
    token: ""
  },
  getters: {},
  mutations: {
    SET_API_MSG(state, payload) {
      const data = { loc: "bot", msg: payload };
      // console.log("set: ", data);
      state.msgBoxList.push(data);
    },
    SET_USR_MSG(state, { name, color }) {
      // console.log("mutations USR_MSG:", name, color);
      const data = { loc: "usr", msg: { name: name, color: color } };
      state.msgBoxList.push(data);
    },
    SET_AUTH(state, { userCi, userName, token }) {
      state.userCi = userCi;
      state.userName = userName;
      state.token - token;
    },
  },
  actions: {
    async GET_GREETING({ commit }) {
      try {
        const res = await greeting();
        // console.log("actions: ", res.data);
        commit("SET_API_MSG", res.data);
      } catch (error) {
        console.log(error);
      }
    },
    async GET_ACTION({ commit }, { id }) {
      try {
        const res = await getAction(id, this.state.userCi);
        let textData = res.data.payload[0].balloons[0].sections[0].data;
        let failCnt = sessionStorage.getItem('failCnt');

        if(failCnt === null || typeof failCnt === 'undefined') {
          failCnt = 0;
        }

        if(textData.indexOf('<font style="color:red;">') !== -1 && parseInt(failCnt) > 9) {
          this.state.msgBoxList.push({
            loc: "bot",
            msg: [
              {
                balloons: [
                  {
                    sections: [
                      {
                        type: "text",
                        data: "10회 오답으로 해피톡을 종료합니다. \n우측 상단 “시나리오 종료” 후 대상 증권을 누르면 다시 해피톡을 진행하실 수 있습니다." 
                      },
                    ],
                  },
                ],
              },
            ],
          });

          return;
        }

        if (res.data.code) {
          commit("SET_API_MSG", res.data.payload);
        }
      } catch (error) {
        console.log('##### ERROR : ', error);
        this.state.msgBoxList.push({
          loc: "bot",
          msg: [
            {
              balloons: [
                {
                  sections: [
                    {
                      type: "text",
                      data: "요청에 실패하였습니다.\nmsg : " + error.message,
                    },
                  ],
                },
              ],
            },
          ],
        });
      }
    },
    async AUTH_SUCCESS({ commit }) {
      try {
        const res = await authSuccess(this.state.userCi, this.state.userName);
        console.log("AUTH_SUCCESS", res.data);
        if (res.data.code) {
          commit("SET_API_MSG", res.data.payload);
        } else {
          this.state.msgBoxList.push({
            loc: "bot",
            msg: [
              {
                balloons: [
                  {
                    sections: [
                      {
                        type: "text",
                        data: "요청에 실패하였습니다.",
                      },
                    ],
                  },
                ],
              },
            ],
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async GET_SCENARIO_GREETING({ commit }, { code, legacyId }) {
      try {
        console.log('##### code : ', code);
        console.log('##### legacyId : ' + legacyId);

        const res = await scenarioGreeting(code, legacyId);
        commit("SET_API_MSG", res.data);
      } catch (error) {
        console.log(error);
      }
    },
    async GET_FINISH_MESSAGE() {
      this.state.msgBoxList.push({
        loc: "bot",
        msg: [
          {
            balloons: [
              {
                sections: [
                  {
                    type: "text",
                    data: "해피톡 상담이 종료되었습니다. 다시 시작하시기를 원하시면 우측상단 \"해피톡 시작\"을 클릭해주세요.",
                  },
                ],
              },
            ],
          },
        ],
      });
    }
  },
  modules: {},
});

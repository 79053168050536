import { createRouter, createWebHistory } from "vue-router";
import HomeView from "@/views/HomeView.vue";
import store from "../store/index";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    beforeEnter: (to, from, next) => {
      const scenarioCode = to.query["Scenario-Code"];
      const legacyId = to.query["legacyId"];

      if (scenarioCode) {
        store.dispatch("GET_SCENARIO_GREETING", { code: scenarioCode, legacyId: legacyId });
        sessionStorage.setItem("Scenario-Code", scenarioCode);
        next();
      } else {
        store.dispatch("GET_GREETING");
        next();
      }
    },
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;

export default {
    install(Vue) {
       const globalProp = Vue.config.globalProperties;

       // 문자열 유틸
       globalProp.$nvl = StringUtils.nvl;
       globalProp.$isEmpty = StringUtils.isEmpty;
       globalProp.$isNotEmpty = StringUtils.isNotEmpty;
       globalProp.$contains = StringUtils.contains;
       globalProp.$replace = StringUtils.replace;
       globalProp.$replaceAll = StringUtils.replaceAll;
       globalProp.$ltrim = StringUtils.ltrim;
       globalProp.$rtrim = StringUtils.rtrim;
       globalProp.$trim = StringUtils.trim;
       globalProp.$removeBlank = StringUtils.removeBlank;
       
       // 날짜 유틸
       globalProp.$getToday = DateUtils.getToday;
       globalProp.$getTime = DateUtils.getTime;
       globalProp.$getDateTime = DateUtils.getDateTime;
    }
}

export const StringUtils = {
    nvl: (str, def) => {
        if(StringUtils.isEmpty(str)) {
            return def;
        }

        return str;
    },
    isEmpty: (str) => {
        if(str === null || str === '' || typeof str === 'undefined') {
            return true;
        } else {
            return false;
        }
    },
    isNotEmpty: (str) => {
        if(str !== null && str !== '' && typeof str !== 'undefined') {
            return true;
        } else {
            return false;
        }
    },
    contains: (str1, str2) => {
        if(str1.indexOf(str2) !== -1) {
            return true;
        }

        return false;
    },
    replace: (str, oldVal, newVal) => {
        return str.replace(oldVal, newVal);
    },
    replaceAll: (str, oldVal, newVal) => {
        return str.split(oldVal).join(newVal);
    },
    ltrim: (str) => {
        return str.replace(/^\s*/, '');
    },
    rtrim: (str) => {
        return str.replace(/\s*$/, '');
    },
    trim: (str) => {
        return str.replace(/\s*$/, '');
    },
    removeBlank: (str) => {
        return str.replace(/(\s*)/g, '');
    }
}

export const DateUtils = {
    getToday: (format) => {
        let d = new Date();
        let year = d.getFullYear();
        let month = d.getMonth() + 1;
        let date = d.getDate();

        if(parseInt(month) < 10) {
            month = '0' + month;
        }

        if(parseInt(date) < 10) {
            date = '0' + date;
        }

        if(StringUtils.isNotEmpty(format)) {
            return year + format + month + format + date;
        }

        return year + '' + month + '' + date;
    },
    getTime: (format) => {
        let d = new Date();
        let hour = d.getHours();
        let minute = d.getMinutes();
        let second = d.getSeconds();

        if(parseInt(hour) < 10) {
            hour = '0' + hour;
        }

        if(parseInt(minute) < 10) {
            minute = '0' + minute;
        }

        if(parseInt(second) < 10) {
            second = '0' + second;
        }

        if(StringUtils.isNotEmpty(format)) {
            return hour + format + minute + format + second;
        }

        return hour + '' + minute + '' + second;
    },
    getDateTime: (isFormat) => {
        if(isFormat) {
            return DateUtils.getToday('-') + ' ' + DateUtils.getTime(':');
        } else {
            return DateUtils.getToday() + '' + DateUtils.getTime();
        }
    }
}
<template>
  <div class="chat-container scroll" style="overflow-y: scroll" id="chatBox">
    <!-- <div v-for="(m, idx) in MsgBoxList" :key="idx"> -->
    <!-- <div class="balloon-container" v-for="(b, idx) in this.testBox" :key="idx"> -->
    <div class="balloon-container" v-for="(b, idx) in msgBoxList" :key="idx">
      <template v-if="b.loc == 'usr'">
        <div class="userChatArea">
          <UserChat :msg="b.msg"></UserChat>
        </div>
      </template>
      <template v-else>
        <div class="botChatArea">
          <div class="profile_container">
            <!-- <p class="profile_text">AIG Korea</p> -->
            <img src="/bot_logo.png" nonce="d66566cb87272021cf692dbdb28056e664a41738855da07c363d8c85dcadaec4" class="profile-image" />
          </div>
          <div class="response_area">
            <div class="profile_name">AIG</div>
            <template v-if="idx + 1 == msgBoxList.length">
              <template v-for="(m, idx) in b.msg" :key="idx">
                <BotChat v-if="m.balloons.length == 1" :msg="m" :btnStatus="true"></BotChat>
                <CarouselBox v-else :msgs="m" :btnStatus="true"></CarouselBox>
              </template>
              <div class="loading-area" v-if="this.$store.state.isLoading">
                <img class="loading-img" src="@/assets/loading.gif" alt="로딩이미지" />
              </div>
            </template>
            <template v-else v-for="(m, idx) in b.msg" :key="idx">
              <BotChat v-if="m.balloons.length == 1" :msg="m"></BotChat>
              <CarouselBox v-else :msgs="m"></CarouselBox>
            </template>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import BotChat from "@/components/BotChat.vue";
import UserChat from "@/components/UserChat.vue";
import TextBox from "./balloons/textBox.vue";
// import ImageBox from "./balloons/ImageBox.vue";
import CarouselBox from "./balloons/CarouselBox.vue";
import BalloonBox from "./balloons/BalloonBox.vue";
import {
  carousel1,
  carousel2,
  textMessage,
  text_button,
  image,
  text_image_button,
  textNbutton,
} from "@/data/testData";

import { mapState } from "vuex";

export default {
  components: {
    TextBox,
    CarouselBox,
    BalloonBox,
    BotChat,
    UserChat,
  },
  data() {
    return {
      length: 0,
      // testBox: {
      //   loc: "bot",
      //   msg: TextBox,
      // },
      testBox: [
        {
          loc: "bot",
          msg: carousel2,
        },
        {
          loc: "usr",
          msg: "하하하핳",
        },
      ],
    };
  },
  async mounted() {
    // if (this.$store.state.msgBoxList.length === 0) {
    //   this.$store.dispatch("GET_GREETING");
    //   console.log("메세지박스: ", await this.msgBoxList);
    // }
    // console.log("테스트박스 확인: ", this.testBox);
    // console.log("메세지 박스 비교 확인: ", this.MsgBoxList[0].msg);

    // this.scrollContainer = document.getElementById("chatBox"); // 변경된 부분

    const receiveMessage = async (e) => {
      if (Object.prototype.hasOwnProperty.call(e.data, "code")) {
        let code = e.data.code;
        let msg = e.data.msg;
        let userCi = e.data.userCi;
        let userName = e.data.userName;
        let token = e.data.token;

        console.log(e.data);
        console.log("code: ", code, " / msg: ", msg, " / userCi: ", userCi, " / userName: ", userName, " / token: ", token);

        if (code === "0000") {
          sessionStorage.setItem("userCi", userCi);
          sessionStorage.setItem("userName", userName);
          sessionStorage.setItem("token", token);
          this.$store.commit("SET_AUTH", { userCi: userCi, userName: userName, token });
          this.authSuccess();
        }
      }
    };

    window.addEventListener("message", receiveMessage, false);
  },
  computed: {
    // ...mapState(["msgBoxList"]),
    msgBoxList() {
      return this.$store.state.msgBoxList;
    },
    profileImg() {
      return require("./Icon/aig.webp");
    },
  },
  methods: {
    authSuccess() {
      this.$store.dispatch("AUTH_SUCCESS");
    },
    log() {
      this.$nextTick(() => {
        console.log("체크");
      });
    },
  },
  watch: {},
};
</script>

<style scoped>
.botChatArea {
  /* display: flex; */
  position: relative !important;
}
.response_area {
  padding-left: 40px;
}
.chat-container {
  background: linear-gradient(0deg, rgba(188, 198, 210, 0.5), rgba(188, 198, 210, 0.5)), #ffffff;
  height: calc(100vh - 114px);
  /* width: 100%; */
  align-items: center;
  overflow-x: hidden;
}

.scroll::-webkit-scrollbar {
  display: none;
}

.scroll {
  -ms-overflow-style: none; /* 인터넷 익스플로러 */
  scrollbar-width: none; /* 파이어폭스 */
}

.balloon-container {
  width: 100%;
  align-items: top;
  margin: 10px;
}
.profile_container {
  position: absolute;
  top: 0;
  /* left: 12px; */
  width: 34px;
  height: 34px;
  background: #001871;
  flex-shrink: 0;
  border-radius: 42% / 42%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-image {
  width: 20px;
  height: 20px;
}

.profile_name {
  /* padding-left: 40px; */
  font-size: 12px;
  font-weight: 400;
  text-align: left;
}
p {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: middle;
}

.chatbotContainer {
  width: 100%;
  height: 800px;
  border: solid black 1px;
}

.chatArea {
  height: 90%;
  background-color: rgba(87, 160, 95, 0.142);
}

.chatbotContainer::-webkit-scrollbar {
  display: none;
}

.chatBot_speechBox,
.chatUser_speechBox {
  background: white;
  min-width: 5px;
  max-width: 300px;
  padding: 10px 12px;
  border-radius: 10px;
  margin: 0px 5px 10px 5px;
}

.chatUser_speechBox > pre {
  text-align: right;
}

.templateText_Area {
  display: inline-flex;
  margin: 10px 0;
}

a:hover {
  cursor: default;
}

img {
  /* width: 100%;
  height: 100%; */
  /* vertical-align: bottom; */
  /* border-radius: 10px; */
}

/* 임시 */
.profile_text {
  color: white;
  font-size: 2pt;
  text-align: center;
  line-height: 10px;
}

.loading-area {
  text-align: left;
  padding-left: 15px;
  margin-top: 20px;
}

.loading-img {
  width: 70px;
}
</style>

<template>
  <div v-for="(contents, idx) in this.data" :key="idx">
    <template v-if="contents.type === 'text'">
      <TextBox></TextBox>
    </template>
    <template v-if="contents.type === 'file'">
      <ImageType></ImageType>
    </template>
  </div>
</template>
<script>
import TextBox from "./textBox.vue";
import ImageType from "./ImageType.vue";
export default {
  components: { TextBox, ImageType },
  data() {
    return {
      sampleData: "",
    };
  },
  props: ["data"],
  created() {},
  mounted() {
    console.log("balloonBox:", this.data);
  },
  unmounted() {},
  methods: {},
};
</script>

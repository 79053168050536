<template>
  <div class="chatBot">
    <div class="chatBot-msgArea">
      <div class="chatBot_speechBox">
        <template v-if="this.msg.balloons.length == 1">
          <div v-for="(s, idx) in this.msg.balloons[0].sections" :key="idx">
            <TitleBox v-if="s.type === 'title'" :title="s"></TitleBox>
            <TextBox v-else-if="s.type === 'text'" :msg="s"></TextBox>
            <ImageBox v-else-if="s.type === 'file'" :file="s"></ImageBox>
            <ButtonType
              v-if="s.type === 'action'"
              :actions="s.actions"
              :btnStatus="btnStatus"></ButtonType>
          </div>
        </template>
      </div>
    </div>
  </div>
  <div>{{ this.data }}</div>
</template>
<script>
import TextBox from "@/components/balloons/textBox.vue";
import ImageBox from "@/components/balloons/ImageBox.vue";
import ButtonType from "@/components/balloons/ButtonType.vue";
import TitleBox from "@/components/balloons/TitleBox.vue";
export default {
  props: {
    msg: {},
    items: Array,
    btnStatus: Boolean,
  },
  components: { TextBox, ImageBox, ButtonType, TitleBox },
  data() {
    return {};
  },
  created() {
    this.$nextTick(() => {
      this.scroll();
    });
  },
  mounted() {
    // console.log("msg 내용: ", this.msg);
    console.log("BotChat: ", this.items);
    scroll();
  },
  methods: {
    scroll() {
      const element = document.getElementById("chatBox");
      const height = document.getElementById("chatBox").scrollHeight;
      element.scrollTop = height;
    },
  },
};
</script>
<style scoped>
.chatBot_speechBox > pre {
  text-align: left;
  font-size: 14px;
}

.chatBot {
  display: flex;
  justify-content: flex-start;
  padding-top: 5px;
}

.chatBot-msgArea {
  position: relative;
  display: inline-block;
}

.chatBot_speechBox {
  background: white;
  min-width: 15px;
  max-width: 250px;
  padding: 10px;
  border-radius: 10px;
  margin: 0px 5px 10px 5px;
}

.chatBot_speechBox:after {
  border-top: 20px solid white;
  border-left: 18px solid transparent;
  /* border-right: 0px solid transparent; */
  /* border-bottom: 0px solid transparent; */
  content: "";
  position: absolute;
  top: 7px;
  left: -4px;
  /* border-radius: 0 15px 0 30px; */
}

.chatBot_speechBox > pre {
  text-align: left;
}
</style>

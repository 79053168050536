<template>
  <header>
    <!--<div class="header-logo">
      <img src="@/assets/AIG_logo.png" alt="logo" class="logo" />
    </div>-->
    <div class="header-text">해피톡</div>
    <button class="header-button" @click="talkClose">{{ closeBtnText }}</button>
  </header>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      sampleData: "",
      closeBtnText: "해피톡 나가기",
      isClosed: false,
    };
  },
  created() {
    if(window.opener) {
      this.closeBtnText = "시나리오 종료";
    }
  },
  mounted() {},
  unmounted() {},
  methods: {
    talkClose() {
      if (this.isClosed) {
        this.isClosed = false;
        location.reload();
      } else {
        if(window.opener) {
          self.close();
        } else {
          if (confirm("해피톡을 종료 하시겠습니까?")) {
            this.isClosed = true;
            this.closeBtnText = "해피톡 시작";

            let buttons = document.getElementsByClassName("button");
            for (let ii = 0; ii < buttons.length; ii++) {
              buttons[ii].disabled = true;
            }

            this.$store.dispatch("GET_FINISH_MESSAGE");
          }
        }
      }
    },
  },
};
</script>

<style scoped>
header {
  height: 64px;
  width: 100%;
  background-color: #001871;
  color: white;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
}
.logo {
  width: 52px;
  height: 32px;
}

.header-logo {
  margin: 0 35px 0 15px;
  display: inline-block;
  width: 5%;
}

.header-text {
  left: 1.5em;
  position: absolute;
  font-weight: 600;
  font-size: 14px;
  line-height: 16.71px;
  font-family: "SF Pro Text";
  font-style: normal;
  width: 90%;
  text-align: left;
  padding-left: 5px;
}

.header-button {
  position: absolute;
  background-color: unset;
  box-sizing: border-box;
  justify-content: center;
  /* padding: 6px 12px; */
  width: 8em;
  height: 32px;
  /* top: calc(50% - 32px / 2 - 0.3px); */
  border: 1px solid #e5e7ec;
  border-radius: 6px;
  color: #e5e7ec;
  /* margin-right: 15px; */
  font-size: 12px;
  /* flex-direction: column; */
  /* display: flex; */
  right: 15px;
}
</style>

<template>
  <div class="chatBot">
    <div class="chatBot-msgArea">
      <div class="chatBot_speechBox">
        <pre v-html="this.msg.data"></pre>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["msg"],
  components: {},
  data() {
    return {};
  },
  created() {},
  mounted() {
    console.log("msg:", this.msg);
  },
  unmounted() {},
  methods: {},
};
</script>

<style scoped>
pre {
  text-align: left;
  font-family: Arial, Helvetica, sans-serif;
  white-space: pre-wrap;
  margin: 5px 0;
  line-height: 1.5;
  font-size: 14px;
}

.chatBot_speechBox > pre {
  text-align: left;
  font-size: 14px;
}
</style>

<template>
  <div class="input-container">
    <input type="text" placeholder="항목을 선택하세요" disabled />
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      sampleData: "",
    };
  },
  created() {},
  mounted() {},
  unmounted() {},
  methods: {},
};
</script>

<style scoped>
.input-container input {
  height: 50px;
  width: 100vw;
  box-sizing: border-box;
}
</style>

<template>
  <img :src="this.imageURL" />
</template>
<script>
export default {
  props: ["file"],
  components: {},
  data() {
    return {
      imageURL: this.file.data,
    };
  },
  created() {},
  mounted() {
    console.log("imgBox : ", this.imageURL);
  },
  unmounted() {},
  methods: {},
};
</script>
<style scoped>
img {
  /* margin: 10px; */
  width: 100%;
}
</style>

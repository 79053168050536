<template>
  <metainfo></metainfo>
  <router-view></router-view>
</template>

<script>
import { useMeta } from 'vue-meta';

export default {
  setup() {
    useMeta({
      title: 'AIG | 해피톡',
      link: [
        {rel: "canonical", href: 'https://www.aig.co.kr'}
      ],
      description: 'AIG손해보험  해피톡',
      meta: [
        {property: 'keywords', content: 'AIG, 보험, 보장, 암, 실버, 차티스'},
        {property: 'og:title', content: 'AIG손해보험 해피톡'},
        {property: 'og:type', content: 'article'},
        {property: 'og:description', content: 'AIG손해보험주식회사 대표이사 램지 알버트 투바시'},
        {property: 'og:url', content: 'https://www.aig.co.kr'},
        {property: 'og:image', content: 'https://www.aig.co.kr/images/common/n_logo.png'},
      ],
    })
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  margin: 0;
  /* width: 100%; */
  /* color: #2c3e50; */
}

body {
  margin: 0;
}
</style>

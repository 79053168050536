<template>
  <div class="chatUser">
    <div class="chatUser-msgArea">
      <div class="chatUser_speechBox">
        <pre :style="{ color: this.msg.color }">{{ this.msg.name }}</pre>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["msg"],
  components: {},
  data() {
    return {};
  },
  created() {},
  mounted() {
    console.log("유저메세지: ", this.msg);
    let color = this.msg.color;

    if(color === '#ff0000') {
      let failCnt = sessionStorage.getItem('failCnt');
      
      if(this.$isEmpty(failCnt)) {
        failCnt = 0;
      } else {
        failCnt = parseInt(failCnt);
      }

      sessionStorage.setItem('failCnt', ++failCnt);
    } else {
      sessionStorage.setItem('failCnt', 0);
    }
  },
  unmounted() {},
  methods: {},
};
</script>

<style scoped>
.chatUser {
  display: flex;
  justify-content: flex-end;
  padding-top: 15px;
  padding-right: 10px;
}

.chatUser-msgArea {
  position: relative;

  /* display: inline-block; */
}

/* .chatUser_speechBox {
  background-color: rgba(0, 255, 55, 0.269);
} */
.chatUser_speechBox {
  background: #feec00;
  min-width: 5px;
  max-width: 300px;
  padding: 10px 12px;
  border-radius: 10px;
  margin: 0px 5px 10px 5px;
  font-size: 14px;
  margin-right: 1.4em;
}
.chatUser_speechBox:after {
  border-top: 20px solid #feec00;
  border-right: 18px solid transparent;
  /* border-left: 0px solid transparent; */
  /* border-bottom: 0px solid transparent; */
  content: "";
  position: absolute;
  top: 7px;
  right: -4px;
  /* border-radius: 0 15px 0 30px; */
  /* transform: rotate(0deg); */
  margin-right: 1em;
}

pre {
  text-align: left;
  font-family: Arial, Helvetica, sans-serif;
  white-space: pre-wrap;
  margin: 3px;
  line-height: 1.5;
}

/* .chatUser_speechBox > pre {
  text-align: right;
} */

.red {
  color: red;
}
</style>

import { createApp } from "vue";
import { createMetaManager } from 'vue-meta';
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { Carousel, CarouselSlide } from "vue3-carousel";
import VueCryptojs from 'vue-cryptojs';
import common from "./common/common.js";

createApp(App).use(createMetaManager()).use(store).use(router).use(Carousel).use(VueCryptojs).use(common).use(CarouselSlide).mount("#app");

export const textMessage = {
  v: "0.1", // 메세지 포맷 버전, 0.1 로 고정
  contentsType: "normal", // 고정, 사용하지 않아도 무방
  balloons: [
    {
      // 말풍선 목록 (캐러셀 대응)
      sections: [
        {
          // 컨텐츠 목록
          type: "text", // 컨텐츠 타입: 텍스트
          data: "텍스트",
        },
      ],
    },
  ],
};

export const image = {
  v: "0.1", // 메세지 포맷 버전, 0.1 로 고정
  contentsType: "normal",
  balloons: [
    {
      // 말풍선 목록 (캐러셀 대응)
      sections: [
        {
          // 컨텐츠 목록
          type: "file", // 컨텐츠 타입: 파일
          display: "image/png", // 파일 타입, 현재 image 까지만 사용
          data: "https://picsum.photos/id/1018/800/600",
        },
      ],
    },
  ],
};

export const text_button = [
  {
    v: "0.1", // 메세지 포맷 버전, 0.1 로 고정
    contentsType: "normal",
    balloons: [
      {
        // 말풍선 목록 (캐러셀 대응)
        sections: [
          {
            type: "text", // 컨텐츠 타입: 텍스트
            data: "텍스트",
          },
          {
            type: "action", // 컨텐츠 타입: 버튼
            actions: [
              {
                // 버튼 목록
                type: "link", // 버튼 타입: 링크
                name: "코드클릭", // 버튼 타이틀
                data: "https://codeclick.co.kr", // 링크
                deviceType: "all", // 디바이스 구분, 요건 없으면 'all'로 고정
              },
              {
                type: "message", // 버튼 타입: 메세지
                name: "메세지 타이틀",
                data: "메세지 내용", // 버튼 타이틀과 고객이 클릭시 발화되는 메세지가 다른 경우 사용
                deviceType: "all",
              },
            ],
          },
        ],
      },
    ],
  },
];

export const text_image_button = [
  {
    v: "0.1", // 메세지 포맷 버전, 0.1 로 고정
    contentsType: "normal",
    balloons: [
      {
        // 말풍선 목록 (캐러셀 대응)
        sections: [
          {
            // 컨텐츠 목록
            type: "file", // 컨텐츠 타입: 파일
            display: "image/png", // 파일 타입, 현재 image 까지만 사용
            data: "https://picsum.photos/id/1018/800/600", // URL
          },
          {
            type: "text", // 컨텐츠 타입: 텍스트
            data: "텍스트",
          },
          {
            type: "action", // 컨텐츠 타입: 버튼
            actions: [
              {
                // 버튼 목록
                type: "link", // 버튼 타입: 링크
                name: "코드클릭", // 버튼 타이틀
                data: "https://codeclick.co.kr", // 링크
                deviceType: "all", // 디바이스 구분, 요건 없으면 'all'로 고정
              },
              {
                type: "link", // 버튼 타입: 메세지
                name: "메세지 타이틀",
                data: "http://localhost:8080?Scenario-Code=123", // 버튼 타이틀과 고객이 클릭시 발화되는 메세지가 다른 경우 사용
                deviceType: "all",
              },
            ],
          },
        ],
      },
    ],
  },
];

export const textNbutton = {
  v: "0.1",
  balloons: [
    {
      sections: [
        {
          type: "text",
          data: "단말기 문제로 유심 인식이 안되는 경우 기본료 환불은 불가합니다. 이 내용을 이해하셨습니까?\n",
        },
        {
          type: "action",
          actions: [
            {
              type: "message",
              name: "네",
              extra: "Bot/Action/192",
              id: 192,
            },
            {
              type: "message",
              name: "아니오",
              extra: "Bot/Action/338?D11",
              id: 338,
            },
          ],
        },
      ],
    },
  ],
  block_id: 176,
};

export const carousel1 = [
  {
    v: "0.1",
    balloons: [
      {
        sections: [
          {
            type: "title",
            data: "222",
          },
          {
            type: "text",
            data: "동해물과백두산이마르고닳도록하느님이보우하사우리나라만세",
          },
          {
            type: "action",
            actions: [
              {
                type: "link",
                name: "2",
                data: "2",
                id: 597,
              },
              {
                type: "link",
                name: "3",
                data: "3",
                id: 597,
              },
            ],
          },
        ],
      },
      {
        sections: [
          {
            type: "title",
            data: "333",
          },
          {
            type: "file",
            data: "333",
            display: "image",
            extra: "test2",
          },
          {
            type: "action",
            actions: [
              {
                type: "link",
                name: "3",
                data: "3",
                id: 598,
              },
            ],
          },
        ],
      },
      {
        sections: [
          {
            type: "title",
            data: "444",
          },
          {
            type: "text",
            data: "444",
          },
          {
            type: "action",
            actions: [
              {
                type: "link",
                name: "4",
                data: "4 4 4",
                id: 599,
              },
              {
                type: "link",
                name: "4",
                data: "4 4 4 ",
                id: 600,
              },
            ],
          },
        ],
      },
    ],
    block_id: 595,
  },
  {
    v: "0.1", // 메세지 포맷 버전, 0.1 로 고정
    contentsType: "normal", // 고정, 사용하지 않아도 무방
    balloons: [
      {
        // 말풍선 목록 (캐러셀 대응)
        sections: [
          {
            // 컨텐츠 목록
            type: "text", // 컨텐츠 타입: 텍스트
            data: "텍스트",
          },
        ],
      },
    ],
  },
];

export const carousel2 = [
  {
    v: "0.1",
    balloons: [
      {
        sections: [
          {
            type: "file",
            data: "https://picsum.photos/id/1015/800/600",
            display: "image",
            extra: "test2",
          },
          {
            type: "title",
            data: "애국가 1절",
          },
          {
            type: "text",
            data: "동해물과 백두산이 마르고 닳도록 하느님이 보우하사 우리나라 만세\n무궁화 삼천리 화려강산 대한사람 대한으로 길이 보전하세",
          },
          {
            type: "action",
            actions: [
              {
                type: "link",
                name: "2",
                data: "2",
                id: 597,
              },
              {
                type: "message",
                name: "blocktestblocktestblocktestblocktestblocktestblocktest",
                extra: "Bot/Action/601?param1",
                id: 601,
              },
            ],
          },
          {
            type: "quick",
            actions: [
              {
                type: "link",
                name: "33",
                data: "http://333",
                id: 602,
              },
            ],
          },
        ],
      },
      {
        sections: [
          {
            type: "title",
            data: "333",
          },
          {
            type: "file",
            data: "https://picsum.photos/id/1009/800/600",
            display: "image",
            extra: "test2",
          },
          {
            type: "action",
            actions: [
              {
                type: "link",
                name: "3",
                data: "3",
                id: 598,
              },
            ],
          },
        ],
      },
      {
        sections: [
          {
            type: "title",
            data: "444",
          },
          {
            type: "text",
            data: "444",
          },
          {
            type: "action",
            actions: [
              {
                type: "link",
                name: "4",
                data: "4 4 4",
                id: 599,
              },
              {
                type: "link",
                name: "4",
                data: "4 4 4",
                id: 600,
              },
            ],
          },
        ],
      },
    ],
    block_id: 595,
  },
];

export const ErrMsg = [
  {
    v: "0.1", // 메세지 포맷 버전, 0.1 로 고정
    // contentsType: "normal", // 고정, 사용하지 않아도 무방
    balloons: [
      {
        // 말풍선 목록 (캐러셀 대응)
        sections: [
          {
            // 컨텐츠 목록
            type: "text", // 컨텐츠 타입: 텍스트
            data: "요청 실패",
          },
        ],
      },
    ],
  },
];

<template>
  <div class="titleArea">{{ this.title.data }}</div>
</template>
<script>
export default {
  props: ["title"],
  components: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  unmounted() {},
  methods: {},
};
</script>

<style scoped>
.titleArea {
  text-align: left;
  font-weight: 700;
  font-size: 1em;
  max-width: 240px;
  white-space: pre-wrap;
}
</style>

<template>
  <div>이미지</div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      sampleData: "",
    };
  },
  created() {},
  mounted() {},
  unmounted() {},
  methods: {},
};
</script>

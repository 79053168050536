<template>
  <div class="buttonContainer">
    <table class="buttonTable">
      <tbody>
        <tr>
          <td v-for="(action, idx) in this.actions" :key="idx">
            <div v-if="action.type === 'link'">
              <template v-if="btnStatus">
                <button class="button" target="_blank" v-if="this.isNotProduct(action.data)" @click="link(action.data)">
                  {{ action.name }}
                </button>
              </template>
              <template v-else>
                <button class="button" target="_blank" disabled>
                  {{ action.name }}
                </button>
              </template>
            </div>
            <div v-if="action.type === 'message'">
              <template v-if="btnStatus">
                <!-- <div class="button" target="_blank"> -->
                <button class="button" @click="message(action)">
                  {{ action.name }}
                </button>
                <!-- </div> -->
              </template>
              <template v-else>
                <button class="button" disabled>
                  {{ action.name }}
                </button>
              </template>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <form id="saForm" name="saForm" method="post">
    <input type="hidden" name="mid" value="" />
    <input type="hidden" name="reqSvcCd" value="01" />
    <input type="hidden" name="mTxId" value="" />
    <input type="hidden" name="authHash" value="" />
    <input type="hidden" name="flgFixedUser" value="N" />
    <input type="hidden" name="userName" value="AIG" />
    <input type="hidden" name="userPhone" value="AIG" />
    <input type="hidden" name="userBirth" value="AIG" />
    <input type="hidden" name="userHash" value="" />
    <input type="hidden" name="reservedMsg" value="isUseToken=Y" />
    <input type="hidden" name="directAgency" value="" />
    <input type="hidden" name="successUrl" value="" />
    <input type="hidden" name="failUrl" value="" />
  </form>
</template>
<script>
export default {
  props: ["actions", "btnStatus"],
  components: {},
  data() {
    return {
      sampleData: "",
      isPopupOpen: null
    };
  },
  created() {},
  mounted() {
    // window.addEventListener("identification", this.inicisCallback);
  },
  unmounted() {},
  methods: {
    link(data) {
      if (this.$contains(data, "inicis")) {
        let url = location.protocol + "//" + location.host + "/cc-external/inicis/identity";

        if (location.host === "localhost:8080") {
          url = "http://localhost:8081/cc-external/inicis/identity";
        }
        let param = "/?userName=ps&userPhone=1123123123&userBirth=101010";

        if(this.$contains(data, "dtcd")) {
          param += "&dtcd=other";
        }

        window.open(
          url + param,
          "인증",
          "width=400, height=700"
        );
      } else {
        //alert(data);
        //data = this.$replaceAll(data, "211.47.6.117", "localhost:8080");
        window.open(data);
      }
    },
    message(m) {
      this.$store.commit("SET_USR_MSG", { name: m.name, color: m.tag_color });
      this.$store.dispatch("GET_ACTION", { id: m.id.toString() });
    },
    isNotProduct(data) {
      if(this.$contains(data, 'Scenario-Code=999999')) {
        return false;
      }
      return true;
    }
    // inicisCallback(code, msg) {
    //   console.log(code, msg);
    // },
  },
};
</script>

<style scoped>
.buttonContainer {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}

.button {
  /* text */
  color: black;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  /* text-decoration: none; */
  display: block;
  width: 240px;
  max-width: 240px;
  /* height: 48px; */
  background: #f6f6f7;
  border: unset;
  border-radius: 8px;
  position: relative;
  line-height: 1.5;
  padding: 10px 0;
  /* padding: 0 calc(100% - 97); */
  margin-bottom: 3px;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  padding-right: 10px;
  padding-left: 10px;
}

table {
  border-collapse: collapse;
  width: 100%;
}

td {
  /* min-width: 80px; */
  /* width: 100%; */
  display: block;
  /* border: 0.1px solid white; */
  /* background-color: rgb(218, 190, 190); */
  /* margin: 4px 10px; */
}

a:hover {
  cursor: default;
}

.buttonTable {
  /* margin: auto; */
}
</style>
